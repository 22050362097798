.home-container{
    width: 100vw;
    height: 92vh;
    background-color: rgb(29, 49, 82);
   
    display: flex;
    justify-content: space-between;
 
}
.left{
    width: 45vw;
    height: 65vh;
    /* position: absolute; */
    margin-left: 100px;
    margin-top: 20vh;
    /* background-color: antiquewhite; */
}
.left p:nth-child(1){
    color: white;
    font-size: 30px;
    font-weight: 500;
}
.left p:nth-child(2){
    color: rgb(244, 221, 15);
    font-size: 50px;
    font-weight: 550;
    margin-top: -10px;
 
}

.span1{
    color: white;
    font-size: 30px;
    font-weight: 500;
    margin-top: -10px;
}
.span2{
    color: aqua;
    font-size: 30px;
    font-weight: 500;
}
.objective{
    color: white;
    font-size: 15px;
    font-weight: 500;
    margin-top: 30px;
}

.second-circle{
    width: 500px;
    height:415px;
    border: 4px solid white;
    /* margin-left: 60vw; */
    /* position: absolute; */
    margin-right: 100px;

    margin-top: 20vh;
    color: 8,90%,63%;
    
}
.image-container{
    width: 500px;
    height: 400px;
    /* border-radius: 50%; */
    margin-left: 7.5px;
    margin-top: 7.5px;

}
.image-container img{
    width: 485px;
    height: 400px;
    /* border-radius: 50%; */
    
}
.icons{
    
    display: flex;
    text-align: center;
    margin-top: 20px;
    

}
.icons span{
    width: 40px;
    height: 40px;
    border: 2px solid aqua;
    border-radius: 50%;
    margin: 10px;
   
}
.icons span:hover{
    
    filter: brightness(200%);
    border: 3px solid rgb(27, 57, 252);
    box-shadow: 2px 2px 2px 2px rgb(27, 57, 252);
}
.icon{
    margin-top: 5px;
    width: 30px;
    height: 30px;
    color: aqua;
}
.btn1 button{
    background-color: aqua;
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 10px;
    font-weight: 700;
    font-size: 15px;
    margin-top: 20px;
    margin-left: 20px;
    

}
.btn1 button:hover{
    color: aqua;
    width: 200px;
    height: 40px;
    background-color: black;
    border: 3px solid rgb(27, 57, 252);
    box-shadow: 3px 3px 3px 3px rgb(27, 57, 252);
    cursor: pointer;
}
.btn1 {
    width:300px ;
    height: 100px;
}