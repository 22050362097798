.c-container{
    width: 100vw;
    height: 90vh;
    background-color: rgb(29, 49, 82);
    color: white;
    
}
.top{
    display: flex;
    justify-content: space-between;
}
.contacts{
    display: flex;
    margin-left: 10vw;
    margin-top: 50px;
    
}
.contacts h1{
    margin-left: 10px;
}
.contacts h1:nth-child(2){
    color: aqua;
}
.mail{
    display: flex;
    margin-top: 30px;
}

.c-icon{
    font-size: 30px;
    margin-left: 100px;
    color: aqua;
}
.num{
font-size: 25px;
font-weight: 600;
margin-left: 20px;
text-decoration: none;
color: white;
}
.contacticons{
    
    display: flex;
    text-align: center;
    margin-top: 50px;
    margin-left: -28vh;
    

}
.contacticons span{
    width: 40px;
    height: 40px;
    border: 2px solid aqua;
    border-radius: 50%;
    margin: 10px;
   
}
.contacticons span:hover{
    filter: brightness(200%);
    border: 3px solid rgb(27, 57, 252);
    box-shadow: 2px 2px 2px 2px rgb(27, 57, 252);
}

form{
    display: flex;
    flex-direction: column;
    margin-right: 20vw;
    margin-top: 30px;
    height: 70vh;
}
form input{
    width: 400px;
    height: 35px;
    margin-top: 20px;
    border-radius: 5px;
    border: 2px solid black;
    background-color: antiquewhite;
    padding: 5px;
}
form button{
    width: 410px;
    height: 35px;
    margin-top: 20px;
    border-radius: 5px;
    border: 2px solid black;
    background-color: aqua;
    color: black;
    font-size: 20px;
    font-weight: 600;
    
}
form button:hover{
    cursor: pointer;
   
    border: 3px solid rgb(27, 57, 252);
    box-shadow: 2px 2px 2px 2px rgb(27, 57, 252);

}
.messageinput{
    height: 150px;
    width: 400px;
    margin-top: 20px;
    border-radius: 5px;
    border: 2px solid black;
    background-color: antiquewhite;
    font-size: 15px;
    font-weight: 500;
    padding: 5px;
}

::placeholder{
    color: black;
    font-size: 15px;
    display: flex;
   
}
.resume{
    width: 350px;
    height: 30px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    margin-left: 100px;
    margin-top: 25px;
    background-color: aqua;
    cursor: pointer;
}
.resume:hover{
    color: aqua;
    background-color: black;
    border: 3px solid rgb(27, 57, 252);
    box-shadow: 2px 2px 2px 2px rgb(27, 57, 252);
}
.resume-container{
    width: 500px;
    height: 300px;
}
.contact-left{
    margin-left: 150px;
}
.empty{
    width: 100vw;
    height: 40px;
    background-color: rgb(39, 91, 74);
   
}