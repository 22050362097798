.a-container{
    width: 100vw;
    height: 100vh;
    background-color: rgb(29, 49, 82);
    display: flex;
    justify-content: space-between;
}
.a-right{
    width: 55vw;
    margin-top: 5vh;
}
.myself{
    margin-top: 80px;
}
.a-right span{
   
    font-size: 40px;
    font-weight: 700;
    color: white;
}
.a-right span.me{
    color: aqua;
}
.a-right h2{
    margin-top: 10px;
    color: rgb(244, 221, 15);
}
.a-right p{
    margin-top: 25px;
    color: white;
    font-size: 17px;
    font-weight: 600;
    width: 45vw;
    
}
.a-right hr{
    width: 217px;
    margin-left: 3px;
    background: rgb(248, 10, 220);
}
.btn3{
    text-decoration: none;
}
.btn3 h3{
    background-color: aqua;
    width: 200px;
    text-align: center;
    height: 35px;
    border-radius: 5px;
    color: rgb(29, 49, 82);
    margin-top: 30px;
    padding-top: 3px;
   
}
.btn3 h3:hover{
    color: aqua;
    
    background-color: black;
    border: 3px solid rgb(27, 57, 252);
    box-shadow: 3px 3px 3px 3px rgb(27, 57, 252);
    cursor: pointer;
}
.a-left{
    width: 45vw;
    height: 80vh;
    margin-top: 5vh;
}

.web-img{
    width: 35vw;
    height: 60vh;
    margin-top: 100px;
    margin-left: 55px;
    border-radius: 10px;
    border: 2px solid rgb(27, 57, 252);
    box-shadow: 5px 5px 5px 5px rgb(27, 57, 252);
    
}