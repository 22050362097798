*{
    margin: 0;padding: 0;
    scroll-behavior: smooth;
}

.h-container{
    width: 100vw;
    height: 8vh;
    background-color: rgb(20, 39, 82);
    background-color: rgb(29, 29, 58);
    
    display: flex;
    justify-content: space-between;
    position: fixed;
    /* overflow: hidden; */
    /* position: fixed; */
}
.h-left p {
    
   
    font-size: 30px;
    font-weight: 700;
    margin: 5px 0px 10px 50px;
}
.h-right ul{
    display: flex;
    list-style: none;
    margin-top: 10px;

}
.h-right ul li a{
    color: white;
    font-size: 20px;
    font-weight: 500;
    list-style: none;
    padding: 20px 30px 0px 30px;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: none;
    
    
    
}
.h-right ul li a:hover{
    color: aqua;
    text-decoration:underline;
    /* filter: brightness(200%); */
}
.h-right{
    margin-right: 30px;
}