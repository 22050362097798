.service-container{
    width: 100vw;
    height: 60vh;
    background-color: rgb(29, 49, 82);
    
}
.service-heading{
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    color: white;
    padding-top: 30px;
    margin-top: 10vh;
}
.service-box{
    display: flex;
    margin-left: 10vw;
    margin-top: 5vh;
   

}
.box{
    width: 25vw;
    height: 25vh;
    /* background-color: rgb(255, 0, 251); */
    margin: 10px;
    border-radius: 10px;
    border: 3px solid rgb(27, 57, 252);
    box-shadow: 5px 5px 5px 5px rgb(27, 57, 252);
}
.box h3{
 color: aqua;
 margin: 10px 10px;
 font-size: 20px;
}
.box p{
   margin-left: 10px;
   margin-right: 10px;
   margin-top: 10px;
   font-weight: 500;
   color: white;
}
